import mediator from "@tvg/mediator";
import {
  RaceCellEventData,
  RaceCellMediatorEventType
} from "@tvg/ts-types/Amplitude";
import { track } from "../../amplitude";
import { ModuleEventProps, EventProps } from "./types";
import { BaseEventProps } from "../../types";
import { EVENT_NAME } from "./constants";
import { getPromoLevel } from "../../utils";

export default () => {
  mediator.base.subscribe(
    RaceCellMediatorEventType.CLICKED,
    ({ payload }: RaceCellEventData) => {
      track<EventProps>(EVENT_NAME.RACE_CELL_CLICK, {
        [BaseEventProps.MODULE]: payload.module,
        [BaseEventProps.TRACK_NAME]: payload.trackName,
        [BaseEventProps.RACE_NUMBER]: payload.raceNumber,
        [BaseEventProps.LINK_URL]: payload.linkUrl,
        [ModuleEventProps.PROMO_LEVEL]: getPromoLevel(
          payload.raceHasPromos,
          payload.trackHasPromos
        ),
        [ModuleEventProps.PROMO_OPTED_IN]: payload.promoOptedIn,
        [ModuleEventProps.IS_FDTV]: payload.isFDTV,
        [ModuleEventProps.TRACK_COUNTRY]: payload.trackCountry,
        [BaseEventProps.TAB_ACTIVE]: payload.tabActive,
        [BaseEventProps.FILTERS_ACTIVE]: payload.activeFilters
      });
    }
  );
};
