import { COMMON_EVENT_NAMES } from "../../constants";

export const EVENT_NAME = {
  OPEN: "Tracks Opened",
  CLOSE: "Tracks Closed",
  RACE_NAVIGATION: COMMON_EVENT_NAMES.NAVIGATED,
  TAB_OPENED: "Tracks Tab Opened",
  FILTER_APPLIED: "Tracks Filter Applied",
  TRACK_FAVORITE: "Track Favorited",
  TRACK_UNFAVORITE: "Track Favorite Removed",
  ALL_FILTERS_MODAL_OPENED: "All Filters Modal Opened"
};
