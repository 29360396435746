import { BaseEventProps } from "../../types";

export enum ModuleEventProps {
  PROMO_LEVEL = "Promo Level",
  PROMO_OPTED_IN = "Promo Opted In",
  IS_FDTV = "Is FDTV",
  TRACK_COUNTRY = "Track Country"
}

export type EventProps = BaseEventProps | ModuleEventProps;
