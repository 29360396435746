import mediator from "@tvg/mediator";
import { get } from "lodash";
import { track } from "../../amplitude";
import {
  MediatorEventType,
  EventProps,
  TrackClickData,
  RaceNavigationData,
  FilterClickData,
  TabOpenedData,
  FavoriteData,
  FilterAppliedData,
  SearchAppliedData,
  ModuleEventProps,
  FiltersModalOpenedData
} from "./types";
import { EVENT_NAME } from "./constants";
import { BaseEventProps } from "../../types";
import { parseModule } from "../../utils";
import { COMMON_EVENT_NAMES } from "../../constants";

export default () => {
  mediator.base.subscribe(
    MediatorEventType.TRACK_CLICK,
    (data: TrackClickData) => {
      track<EventProps>(
        get(data, "payload.isOpen") ? EVENT_NAME.CLOSE : EVENT_NAME.OPEN,
        {
          [BaseEventProps.EVENT_CATEGORY]: COMMON_EVENT_NAMES.SITE_CLICK,
          [BaseEventProps.EVENT_LABEL]: get(data, "payload.trackName"),
          [BaseEventProps.MODULE]: parseModule(
            get(data, "payload.module", "Top Tracks")
          )
        }
      );
    }
  );

  mediator.base.subscribe(
    MediatorEventType.RACE_NAVIGATION,
    (data: RaceNavigationData) => {
      track<EventProps>(EVENT_NAME.RACE_NAVIGATION, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.LINK_TEXT]: `${get(data, "payload.trackName")} - R${get(
          data,
          "payload.raceNumber"
        )}${get(data, "payload.mtp")}`,
        [BaseEventProps.MODULE]: parseModule(get(data, "payload.module")),
        [BaseEventProps.LINK_URL]: get(data, "payload.url")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.FILTER_CLICK,
    (data: FilterClickData) => {
      track<EventProps>(
        get(data, "payload.isSelecting") ? "Select Filter" : "Deselect Filter",
        {
          [BaseEventProps.EVENT_CATEGORY]: "Filters",
          [BaseEventProps.EVENT_LABEL]: get(data, "payload.filter"),
          [BaseEventProps.EVENT_VALUE]: undefined,
          [BaseEventProps.MODULE]: "Filter",
          [BaseEventProps.UP_NEXT_TRACKS]:
            get(data, "payload.numRacesUpNext") &&
            get(data, "payload.numRacesResultUpNext")
              ? `${get(data, "payload.numRacesUpNext")} - ${get(
                  data,
                  "payload.numRacesResultUpNext"
                )}`
              : "undefined",
          [BaseEventProps.FAV_TRACKS]:
            get(data, "payload.numRacesFavorites") &&
            get(data, "payload.numRacesResultFavorites")
              ? `${get(data, "payload.numRacesFavorites")} - ${get(
                  data,
                  "payload.numRacesResultFavorites"
                )}`
              : "undefined",
          [BaseEventProps.A_TO_Z_TRACKS]:
            get(data, "payload.numRacesAZ") &&
            get(data, "payload.numRacesResultAZ")
              ? `${get(data, "payload.numRacesAZ")} - ${get(
                  data,
                  "payload.numRacesResultAZ"
                )}`
              : "undefined"
        }
      );
    }
  );

  mediator.base.subscribe(
    MediatorEventType.TAB_OPENED,
    (data: TabOpenedData) => {
      track<EventProps>(COMMON_EVENT_NAMES.NAVIGATED, {
        [BaseEventProps.EVENT_CATEGORY]: "Navigation",
        [BaseEventProps.MODULE]: "tracks_tab_header",
        [BaseEventProps.LINK_TEXT]: get(data, "payload.tabName"),
        [BaseEventProps.LINK_URL]: get(data, "payload.url")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.FILTER_APPLIED,
    (data: FilterAppliedData) => {
      track<EventProps>(EVENT_NAME.FILTER_APPLIED, {
        [BaseEventProps.MODULE]: "tracks_filters",
        [ModuleEventProps.FILTER_TYPE]: get(data, "payload.filterType"),
        [BaseEventProps.FILTER_NAME]: get(data, "payload.filterName"),
        [BaseEventProps.FILTER_VALUE]: get(data, "payload.filterValue"),
        [BaseEventProps.TAB_ACTIVE]: get(data, "payload.activeTab")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.SEARCH_APPLIED,
    (data: SearchAppliedData) => {
      track<EventProps>(EVENT_NAME.FILTER_APPLIED, {
        [BaseEventProps.MODULE]: "tracks_filters",
        [ModuleEventProps.FILTER_TYPE]: get(data, "payload.filterType"),
        [BaseEventProps.FILTER_NAME]: "tracks_search",
        [BaseEventProps.TAB_ACTIVE]: get(data, "payload.activeTab")
      });
    }
  );

  mediator.base.subscribe(
    MediatorEventType.TRACKS_FAVORITE,
    (data: FavoriteData) => {
      track<EventProps>(
        get(data, "payload.isFavoriting")
          ? EVENT_NAME.TRACK_FAVORITE
          : EVENT_NAME.TRACK_UNFAVORITE,
        {
          [BaseEventProps.MODULE]: get(data, "payload.module"),
          [BaseEventProps.TRACK_NAME]: get(data, "payload.trackName"),
          [BaseEventProps.TAB_ACTIVE]: get(data, "payload.activeTab")
        }
      );
    }
  );

  mediator.base.subscribe(
    MediatorEventType.ALL_FILTERS_MODAL_OPENED,
    (data: FiltersModalOpenedData) => {
      track<EventProps>(EVENT_NAME.ALL_FILTERS_MODAL_OPENED, {
        [BaseEventProps.MODULE]: get(data, "payload.module"),
        [BaseEventProps.TAB_ACTIVE]: get(data, "payload.activeTab")
      });
    }
  );
};
