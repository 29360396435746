import { RaceStatusEnum } from "@tvg/ts-types/Race";
import { isFDR } from "@tvg/utils/generalUtils";

export const COMMON_EVENT_NAMES = {
  SITE_CLICK: "Site Click",
  PAGE_VIEWED: "PAGE VIEWED",
  NAVIGATED: "Navigation Link Clicked",
  OPEN: "Opened",
  CLOSE: "Closed",
  DELETED: "Deleted"
};

export const PAGE_NAME_KEYS = {
  HOME: "home",
  TRACK_INFORMATION: "track_information",
  PROGRAM_PAGE: "program_page",
  PROMOS: "promos",
  PROMOTIONS: "promotions",
  PROMO_OFFER: "promo_offer"
};

export const MEP_PAGE_CONFIG = [
  {
    page: PAGE_NAME_KEYS.TRACK_INFORMATION,
    urlReg: /^\/racetracks((?!race=).)*$/
  },
  { page: PAGE_NAME_KEYS.PROGRAM_PAGE, urlReg: /\/racetracks\/.*\?race=.+/ },
  {
    page: PAGE_NAME_KEYS.PROMO_OFFER,
    urlReg: /(\?promo=[^&\s]*|\/promos\/.*)/
  },
  { page: PAGE_NAME_KEYS.PROMOTIONS, urlReg: /(\/\w+)?#promos/ },
  { page: PAGE_NAME_KEYS.PROGRAM_PAGE, urlReg: /\/live\/TVG\d?\/.*\?race=.+/ }
];

export const PAGE_NAMES = {
  [PAGE_NAME_KEYS.HOME]: "Home",
  [PAGE_NAME_KEYS.TRACK_INFORMATION]: "Track Information",
  [PAGE_NAME_KEYS.PROGRAM_PAGE]: "Program",
  [PAGE_NAME_KEYS.PROMOS]: "Promotions",
  [PAGE_NAME_KEYS.PROMOTIONS]: "Promotions",
  [PAGE_NAME_KEYS.PROMO_OFFER]: "Promo Offer"
};

const TVG_PAGE_CONFIG = [
  {
    page: PAGE_NAME_KEYS.TRACK_INFORMATION,
    urlReg: /^\/racetracks((?!race=).)*$/
  },
  { page: PAGE_NAME_KEYS.PROGRAM_PAGE, urlReg: /\/racetracks\/.*\?race=.+/ },
  { page: PAGE_NAME_KEYS.PROGRAM_PAGE, urlReg: /\/live\/TVG\d?\/.*\?race=.+/ },
  { page: PAGE_NAME_KEYS.PROMO_OFFER, urlReg: /\/promos\/[^?].+/ },
  { page: PAGE_NAME_KEYS.PROMOTIONS, urlReg: /^\/promos/ }
];

const FDR_PAGE_CONFIG = [
  {
    page: PAGE_NAME_KEYS.TRACK_INFORMATION,
    urlReg: /^\/racetracks((?!race=).)*$/
  },
  { page: PAGE_NAME_KEYS.PROGRAM_PAGE, urlReg: /\/racetracks/ },
  { page: PAGE_NAME_KEYS.PROGRAM_PAGE, urlReg: /\/live\/TVG\d?\/.*\?race=.+/ },
  { page: PAGE_NAME_KEYS.PROMO_OFFER, urlReg: /\/?promo=(?!s\b)/ },
  { page: PAGE_NAME_KEYS.PROMOTIONS, urlReg: /(\/\w+)?#promos/ }
];

const isFDRProduct = isFDR();

export const URP_PAGE_CONFIG = isFDRProduct ? FDR_PAGE_CONFIG : TVG_PAGE_CONFIG;

export const RACE_STATUS_MAPPER = {
  [RaceStatusEnum.OPEN]: "mtp",
  [RaceStatusEnum.UP_NEXT]: "mtp",
  [RaceStatusEnum.RACE_OFFICIAL]: "resulted",
  [RaceStatusEnum.RACE_OFF]: "race_off"
};

export const DEVICE_ID_COOKIE = "amp_device_id";
export const DEVICE_ID_COOKIE_MAX_AGE = 31622400;

export const SESSION_ID_COOKIE = "amp_session_id";
export const SESSION_ID_COOKIE_MAX_AGE = 1800;

export const JURISDICTION_LOCAL_STORAGE_ITEM = "userAuthenticationState";

export const XSELL_PREFIX = "FDR XS";
